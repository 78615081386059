<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="/data-statistics/maintain-elevator-statistics/page">
      <el-button
        v-if="$auth('保养清单统计导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" width="150" align="center"></el-table-column>
      <el-table-column prop="buildingName" label="楼宇名称" width="150" align="center"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" width="180" align="center"></el-table-column>
      <el-table-column prop="maintenanceTypeDesc" label="电梯维保模式" width="100" align="center"></el-table-column>
      <el-table-column prop="elevatorStatus" label="电梯状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.deleted" type="danger">注销</el-tag>
          <el-tag v-else-if="scope.row.elevatorStatus === 1">启用</el-tag>
          <el-tag v-else type="success">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="uploadNum" label="成功上传96333平台次数" width="160" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="goToDetail(scope.row)">{{scope.row.uploadNum}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="leaderName" label="项目队长姓名" width="100" align="center"></el-table-column>
      <el-table-column prop="leaderPhone" label="项目队长联系电话" width="120" align="center"></el-table-column>
      <el-table-column prop="lastMaintainDate" label="上次保养时间" width="140" align="center"></el-table-column>
      <el-table-column prop="lastMaintainTypeDesc" label="上次保养类型" width="100" align="center"></el-table-column>
      <el-table-column prop="primaryName" label="保养人员一" width="100" align="center"></el-table-column>
      <el-table-column prop="secondaryName" label="保养人员二" width="100" align="center"></el-table-column>
      <el-table-column prop="lastUploadDate" label="上次上传96333日期" width="140" align="center"></el-table-column>
      <el-table-column prop="isUpload" label="上传是否成功" width="100" align="center"></el-table-column>
      <el-table-column prop="intervalDays" label="距上次保养间隔天数" width="100" align="center">
        <template slot="header" slot-scope="">
          间隔天数 <el-button type="text" icon="el-icon-sort" @click="sort('intervalDays')"></el-button>
        </template>

        <template slot-scope="scope">
          <span :style="{color:scope.row.intervalDays>90?'red':'black' }">{{scope.row.intervalDays}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nextMaintainDate" label="下次计划保养日期" width="140" align="center"></el-table-column>
      <el-table-column prop="maintainTypeDesc" label="计划保养类型" width="140" align="center"></el-table-column>
      <el-table-column prop="lastSixDate" label="上次半年保日期" width="140" align="center"></el-table-column>
      <el-table-column prop="lastTwelveDate" label="上次年度保日期" width="140" align="center"></el-table-column>
      <el-table-column prop="firstThreeDate" label="近一次季度保养日期" width="140" align="center"></el-table-column>
      <el-table-column prop="secondThreeDate" label="近二次季度保养日期" width="140" align="center"></el-table-column>
      <el-table-column prop="item" label="特记事项"></el-table-column>
    </vm-table>
    <maintain-checklist-detail ref="maintainChecklistDetail" @save-success="getList(-1)"></maintain-checklist-detail>
  </div>
</template>

<script>
  import {export2Excel} from "@/util";
  import moment from "moment";
  import MaintainChecklistDetail from "@/views/dataStatistics/MaintainChecklistDetail.vue";

  export default {
    components: {MaintainChecklistDetail},
    name: "MaintainChecklist",
    data() {
      return {
        tableData: [],
        filter: {

          arr:[{
            orderColumnName:"intervalDays",
            asc:0,
          }],
          orderColumnNames:"intervalDays|0",
        },
      };
    },
    mounted() {
    // this.getData();
    },
    methods:{
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          regCode:"注册代码",
          maintenanceType:"电梯维保模式",
          elevatorStatus:"电梯状态",
          uploadNum:"成功上传96333平台次数",
          leaderName:"项目队长姓名",
          leaderPhone:"项目队长联系电话",
          lastMaintainDate:"上次保养时间",
          lastMaintainType:"上次保养类型",
          primaryName:"保养人员一",
          secondaryName:"保养人员二",
          lastUploadDate:"上次上传96333日期",
          isUpload:"上传是否成功",
          intervalDays:"距上次保养间隔天数",
          nextMaintainDate:"下次计划保养日期",
          maintainType:"计划保养类型",
          lastSixDate:"上次半年保日期",
          lastTwelveDate:"上次年度保日期",
          firstThreeDate:"近一次季度保养日期",
          secondThreeDate:"近二次季度保养日期",
          item:"特记事项",
        };
        this.$http.get("data-statistics/maintain-elevator-statistics/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`保养清单统计-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      goToDetail(row) {
        this.$refs.maintainChecklistDetail.open(row.orderId);
      },
      getData() {
        this.$http.get("elevator/maintain-order/list").then(res=>{
          this.tableData= res;
        });
      },
    },
  };
</script>

<style scoped>

</style>
