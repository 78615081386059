<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'96333上传详情':'96333上传详情'"
    width="1200px"
    :modal="false"
    top="3vh"
    @close="$reset('form')">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="message/message-approve/page">
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column label="上传" align="center" prop="approveStatus">
        <template slot-scope="scope">
          <span v-if="scope.row.approveStatus === 1">成功</span>
          <span v-else>失败</span>
        </template>
      </el-table-column>
      <el-table-column label="消息" align="center" prop="message">
      </el-table-column>
    </vm-table>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        id:"",
        value1:false,
        yaixs:[],
        formatData:{
          xaxis: [],
          data:[],
          record:[],
        },
        filter: {
          orderColumnName:"",
          asc:0,
          maintenanceType:"as_need",
        },
        model:{},
      };
    },
    methods: {
      open(orderId) {
        this.filter.orderId= orderId;
        // this.model.teamId = teamId;
        this.dialogVisible = true;
        this.getList(1);
      },
      getList(pageNum) {
        let time=window.setTimeout(()=>{
          this.$refs.vmTable.getList(pageNum);
          window.clearTimeout(time);
        },200);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.isp) {
              this.model.isp=null;
            }
            this.$http
              .save("elevator-iot/device", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      realEstateSelect(row) {
        this.$set(this.model,"realEstateId", row.id);
        this.$set(this.model,"realEstateName", row.name);

      },
      buildingSelect(row) {
        this.$set(this.model,"buildingId", row.id);
        this.$set(this.model,"buildingName", row.name);

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
